<template>
  <div>
    <affiliate-statement></affiliate-statement>
  </div>
</template>

<script>
import AffiliateStatement from "@/components/AffiliateStatement.vue"

export default {
  components: {
    AffiliateStatement,
  },
}
</script>